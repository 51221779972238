@import "variables";

.waiting {
    .bigheader {
        min-height: calc(100vh - 13.3em);
    }
}

.waiting_messages {
    position: relative;
    z-index: 3;
    color: #fff;
    padding-top: 1em;

    a {
        color: inherit;
        font-weight: 600;
    }

    .status {
        display: inline-block;
        margin: -4px 2em 0 1px;
        height: 1.5em;
        width: 1.5em;
        vertical-align: middle;
        box-sizing: border-box;
        line-height: 1.4em;
        text-align: center;
        font-size: 1em;
        color: #fff;

        &:before {
            font-size: 1.25em;
        }
    }

    .status_not_started .status {
        border-radius: 50%;
        border: 1px solid var(--grey-dark);
    }

    .status_in_progress .status {
        --ld_size: 1.3em;
        --ld_border: 0.2em;
        position: relative;
        border-radius: 50%;

        &:after {
            margin: -1px 0 0 -1px;
        }

    }

    .status_done .status {
        border-radius: 4px;
        background: #33cc00;

    }

    .status_errored .status {
        border-radius: 4px;
        background: #cb2910;
    }


}

#status-list {
    list-style: none;
    padding-left: 0;
    line-height: 2em;

    li {
        font-size: 0.9em;
        color: var(--grey-dark);
        overflow: hidden;
        height: 2.22em;
        transition: all 0.350s linear;
    }

    .status_done,
    .status_in_progress {
        color: #fff;
    }

    .status_errored {
        color: #cb4545
    }
}

.message_error {
    overflow: hidden;
    max-height: 0;
    transition: all 0.350s linear;

    p {
        margin: 0 0 0.5em;
    }

    p,
    li {
        line-height: 1.4em;
        font-size: 0.9em;
        color: var(--grey-light);
        margin: 0 0 0.5em;

        li {
            font-size: 1em;
        }
    }
}

#status-list:has(#status_pageData.status_errored) li,
#status-list:has(#status_ylt.status_errored) li,
#status-list:has(#status_wpt.status_errored) li {
    height: 0;
}

.required.status_errored {
    height: 2.22em !important;
    color: #fff !important;
    background: #cb2c10;
    border-radius: 6px;

    .status {
        margin: -4px 1.5em 0 .5em;
    }

    &~.status_errored {
        display: none;
    }
}

#status-list:has(#status_pageData.status_errored)~#error_pageData,
#status-list:has(#status_ylt.status_errored)~#error_ylt,
#status-list:has(#status_wpt.status_errored)~#error_wpt {
    max-height: 30em;

    &~.message_error {
        display: none;
    }
}




@media (max-width: $mq-2 - 1px) {
    .waiting .bigheader .grid {
        display: block;
    }

    .complete_milkshake {
        display: none;
    }
}

@media (min-width: $mq-1 ) {
    .waiting_messages {
        max-width: 1326px;
        padding-top: 1.5em;
    }
}

@media (min-width: $mq-2 ) {
    .waiting .waiting_messages {
        grid-row-start: 3;
    }

    #status-list,
    .message_error {
        padding-left: 4em;
    }

    .complete_milkshake {
        grid-column: span 2;
        margin-top: -17em !important;
    }

    .waiting .bigheader {
        min-height: calc(100vh - 8.3em);
    }
}